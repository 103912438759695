export const HOME_SCREEN = "/"; 
export const VERIFY_EMAIL_SCREEN = "/verifier-mon-email"; 
export const SIGNUP_SCREEN = "/connexion";
export const DEFINE_PWD_SCREEN = "/nouveau-mot-de-passe";
export const TRANSACTIONS_SCREEN = "/transactions";
export const MERCHANTS_SCREEN = "/marchants";
export const SPONSORSHIP_SCREEN = "/parrainage";
export const ADMIN_SCREEN = "/admin";
export const WALLET_SCREEN = "/portefeuille";
export const ACCOUNTS_SCREEN = "/compte-marchants";
export const ANALYSIS_SCREEN = "/bilan";
export const AGENTS_SCREEN = "/agents";
export const REGLAGES_SCREEN = "/reglages";
export const NOTIFICATIONS_SCREEN = "/notifications";
export const CART_SCREEN = "/paniers";


/* export const API_URL = "http://localhost:4000/api/";
export const API_URL2 = "http://localhost:4000/";
 */

export const API_URL = "https://api-admin-exchange.fifarent.com/api/";
export const API_URL2 = "https://api-admin-exchange.fifarent.com/";  