import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import "./Layout.scss";
import SideBar from "../components/sideBar/SideBar";
import { Badge } from "primereact/badge";
import { useAuth } from "../context/AuthContext";
import { useGlobalStore } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { NOTIFICATIONS_SCREEN } from "../settings/constant";

interface LayoutProps {
    children: ReactNode,
    title?: string;
    showBreadcrumb?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, title, showBreadcrumb = true }) => {
    const { getNotifications }  = useGlobalStore();
    const navigate = useNavigate();
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [nbNotify, setNbNotify] = useState(0);
    const { user } = useAuth();
    const items = [{ label: title },];
    const home = { icon: '',  }

   useEffect(() => {
    const fetchData = async() => {
        try {
            const formData = {
                type: "admin"
            }

            const { data } = await getNotifications(formData);
            if (data.length !== 0) {
                const filter = data.filter(item => !item.view);
                setNbNotify(filter.length);
            }
        } catch (error) {
            console.log(error);
        }
    }

    fetchData();
   }, []);
   
    return (
        <main className="MainApp">
            <SideBar isVisible={isSidebarVisible} setIsVisible={setIsSidebarVisible} />
            <div className="MainApp-center">
                <div className="Header">
                    <button 
                        className="Header-hambuger pi pi-bars"
                        onClick={() => setIsSidebarVisible(!isSidebarVisible)} 
                        />
                    <p className="Header-text">Hello <span>agent</span> <b>{user?._doc?.name}</b> !</p>
                    <button className="Header-right" onClick={() => navigate(NOTIFICATIONS_SCREEN)}>
                        <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.35rem' }}>
                            <Badge value={nbNotify} severity={"warning"}></Badge>
                        </i>
                        {/* <Button className="Header-logout" icon="pi pi-sign-out" /> */}
                    </button>
                </div>
                <div className="MainApp-container">
                    {showBreadcrumb && <BreadCrumb className="" model={items} home={home} />}
                    <div className={`MainApp-block ${showBreadcrumb ? 'border':''} `}>{children}</div>
                </div>
            </div>
        </main>
    );
};

export default Layout;