import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { API_URL, SIGNUP_SCREEN } from "../settings/constant";
import axiosInstanceUser from "../config/axiosConfig";

// Interface pour définir les types des fonctions d'authentification
interface GlobalContextType {
  getAccountSubmitted: () => Promise<{ status: number, data: any[]}>;
  approveOrRejectMerchant: (formData: any) => Promise<number>;
  createUserAdmin: (formData: any) => Promise<number>;
  updateUserAdmin: (id: string, formData: any) => Promise<number>;
  deleteUserAdmin: (id: string) => Promise<number>;
  getUserAdmin: () => Promise<{status: number, data: any[]}>;
  getUserAdminById: () => Promise<{status: number, data: any}>;
  getTransactions: () => Promise<{status: number, data: any[]}>;
  getTransactionsById: (id: string) => Promise<{status: number, data: any}>;
  updateTransaction: (formData: any, id: string) => Promise<number>;
  downloadTransaction: (status: string) => Promise<number>;
  getBalance: () => Promise<{status: number, data: any}>;
  getStats: () => Promise<{status: number, data: any}>;
  updatePercentExchange: (percent: number ) => Promise<number>;
  getSettings: () => Promise<{status: number, data: any}>;
  updateBalance: (formData: any ) => Promise<number>;
  getNotifications: (formData: any ) => Promise<{status: number, data: any[]}>;
  updateNotifications: (id: string ) => Promise<number>;
  getStatsGraph: (year: number ) => Promise<{status: number, data: any}>;
  getStatsCommission: (formData: any ) => Promise<{status: number, data: any}>;
  getAllCart: () => Promise<{status: number, data: any[]}>;
  createCart: (formData: any ) => Promise<number>;
  updateCart: (id: number, formData: any ) => Promise<number>;
  deleteCart: (id: number) => Promise<number>;
  updatePhone: (formData: any ) => Promise<number>;
  //accountSubmitted: any[];
  //userAdmin: any[];
}


// Création du contexte d'authentification
const GlobalContext = createContext<GlobalContextType>({
    getAccountSubmitted: async () => ({ status: 500, data: [] }),
    approveOrRejectMerchant: async () => { return 500 },
    createUserAdmin: async () => { return 500 },
    updateUserAdmin: async () => { return 500 },
    deleteUserAdmin: async () => { return 500 },
    getUserAdmin: async () => ({ status: 500, data: [] }),
    getUserAdminById: async () => ({ status: 500, data: null }),
    getTransactions: async () => ({ status: 500, data: [] }),
    getTransactionsById: async () => ({ status: 500, data: null }),
    updateTransaction: async () => { return 500 },
    downloadTransaction: async () => { return 500 },
    getBalance: async () => ({ status: 500, data: null }),
    getStats: async () => ({ status: 500, data: null }),
    updatePercentExchange: async () => { return 500 },
    updateBalance: async () => { return 500 },
    getSettings: async () => ({ status: 500, data: null }),
    getNotifications: async () => ({ status: 500, data: [] }),
    updateNotifications: async () => { return 500 },
    getStatsGraph: async () => ({ status: 500, data: null }),
    getStatsCommission: async () => ({ status: 500, data: null }),
    getAllCart: async () => ({ status: 500, data: [] }),
    createCart: async () => { return 500 },
    updateCart: async () => { return 500 },
    deleteCart: async () => { return 500 },
    updatePhone: async () => { return 500 },
    //accountSubmitted: [],
    //userAdmin: []
});

// Hook personnalisé pour utiliser le contexte d'authentification dans les composants
export const useGlobalStore = (): GlobalContextType => useContext(GlobalContext);

// Composant fournisseur de contexte d'authentification
export const GlobalStoreProvider = ({ children }: { children: ReactNode }) => {

  const redirectLogin = async (errorStatus: number) => {
    if (errorStatus === 401) {
        localStorage.removeItem('accessToken');
        window.location.href = SIGNUP_SCREEN
    }
  }


  const getAccountSubmitted = async (): Promise<{ status: number, data: any[] }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}merchant/submitted`);
      console.log(response.data)
      return { status: response.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la récupération des informations utilisateur :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: 500, data: []};
    }
  }

  const approveOrRejectMerchant = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}admin/approve-account`, formData);
      console.log(response.data)
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      return error.response?.status || 500;
    }
  }

  const getUserAdmin = async (): Promise<{ status: number, data: any[] }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}admin/get-user`);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: [] };
    }
  }

  const getUserAdminById = async (): Promise<{ status: number, data: any[] }> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}admin/get-user-byId`);
      console.log(response.data)
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      return { status: error.response?.status || 500, data: [] };
    }
  }

  const createUserAdmin = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}admin/create-user`, formData);
      console.log(response.data)
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      return error.response?.status || 500;
    }
  }

  const updateUserAdmin = async (id: string, formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}admin/update-user/${id}`, formData);
      console.log(response.data)
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      return error.response?.status || 500;
    }
  }

  const deleteUserAdmin = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.delete(`${API_URL}admin/delete-user/${id}`);
      console.log(response.data)
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      return error.response?.status || 500;
    }
  }

  // Transactions

  const getTransactions = async (): Promise<{ status: number, data: any[] }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}transactions/get`);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: [] };
    }
  }

  const getTransactionsById = async (id: string): Promise<{ status: number, data: any }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}transactions/get-byId/${id}`);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: null };
    }
  }

  const updateTransaction = async (formData: any, id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}admin/update-transaction/${id}`, formData);
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const downloadTransaction = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}admin/download-transactions`, formData,{

          responseType: "blob", // Spécification du type de réponse blob
          headers: {
            "Content-Type": "application/json"
          }
      });
      console.log(response)
      if (response.status === 200) {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        // Créer un lien de téléchargement et le déclencher
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `transaction.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        // Libérer l'URL d'objet
        window.URL.revokeObjectURL(url);
      }

      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  };

  // Balance
  const getBalance = async (): Promise<{ status: number, data: any }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}balance/get`);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: null };
    }
  }

  // Stats
  const getStats = async (): Promise<{ status: number, data: any }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}stats/get`);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: null };
    }
  }

  const getSettings = async (): Promise<{ status: number, data: any }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}settings/get`);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: null };
    }
  }

  const updatePercentExchange = async (percent: number): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}settings/update-percent`, 
        { exchangeRate: percent });
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const updateBalance = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}settings/update-balance`, formData);
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const getNotifications = async (formData: any): Promise<{ status: number, data: any[] }> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}notifications`, formData);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: [] };
    }
  }

  const updateNotifications = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}notifications/update/${id}`);
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const getStatsGraph = async (year: number): Promise<{ status: number, data: any }> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}stats/graph/${year}`);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: null };
    }
  }

  const getStatsCommission = async (formData: any): Promise<{ status: number, data: any }> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}stats/commission`, formData);
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: null };
    }
  }

  // Cart
  const getAllCart = async (): Promise<{ status: number, data: any[] }> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}cart/get`);
      console.log(response.data)
      return { status: response?.status, data: response.data };
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return { status: error.response?.status || 500, data: [] };
    }
  }

  const createCart = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}cart/create`, formData);
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const updateCart = async (id: number, formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}cart/update/${id}`, formData);
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const deleteCart = async (id: number): Promise<number> => {
    try {
      const response = await axiosInstanceUser.delete(`${API_URL}cart/remove/${id}`);
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const updatePhone = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}settings/update-phone`, formData);
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la  :", error);
      redirectLogin(error?.response?.status || error?.status);
      return error.response?.status || 500;
    }
  }

  const context = {
    getAccountSubmitted,
    approveOrRejectMerchant,
    createUserAdmin,
    updateUserAdmin,
    deleteUserAdmin,
    getUserAdmin,
    getUserAdminById,
    getTransactions,
    getTransactionsById,
    updateTransaction,
    downloadTransaction,
    getBalance,
    getStats,
    updatePercentExchange,
    getSettings,
    updateBalance,
    getNotifications,
    updateNotifications,
    getStatsGraph,
    getStatsCommission,
    getAllCart,
    createCart,
    updateCart,
    deleteCart,
    updatePhone
  };

  useEffect(() => {
    
  }, []);

  return <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>;
};
